export type   EventType = number;
export const  EventType = {
    SAAS_CLUSTER_RELEASE: 1,
    1: "SAAS_CLUSTER_RELEASE",
    MANAGED_CLUSTER_RELEASE: 2,
    2: "MANAGED_CLUSTER_RELEASE",
    MILESTONE: 3,
    3: "MILESTONE",
    PRIVATE_VALUE_EVENT: 4,
    4: "PRIVATE_VALUE_EVENT",
    PUBLIC_VALUE_EVENT: 5,
    5: "PUBLIC_VALUE_EVENT",
    DYNATRACE_VALUE_EVENT: 6,
    6: "DYNATRACE_VALUE_EVENT",
    QUARTERLY_BUSINESS_REVIEW: 7,
    7: "QUARTERLY_BUSINESS_REVIEW",
    VELOCITY_SERVICES_EVENT: 8,
    8: "VELOCITY_SERVICES_EVENT",
    ESA_SERVICES_EVENT: 9,
    9: "ESA_SERVICES_EVENT",
    EPM_SERVICES_EVENT: 10,
    10: "EPM_SERVICES_EVENT",
    PROJECT_MEETING: 11,
    11: "PROJECT_MEETING",
    TRAINING_SESSION: 12,
    12: "TRAINING_SESSION",
    OBJECTIVE: 14,
    14: "OBJECTIVE",
    GOAL: 15,
    15: "GOAL",
    TASK: 16,
    16: "TASK",
    COVERAGE_TASK: 17,
    17: "COVERAGE_TASK",
    MEETING_EVENT: 18,
    18: "MEETING_EVENT",
    CALENDAR_EVENT: 19,
    19: "CALENDAR_EVENT",
    PROJECT: 100,
    100: "PROJECT",
    COVERAGE_GOAL: 101,
    101: "COVERAGE_GOAL",
    COVERAGE_OBJECTIVE: 102,
    102: "COVERAGE_OBJECTIVE",
    ISSUE: 103,
    103: "ISSUE",
    RISK: 104,
    104: "RISK",
    get values() { return Object.keys(this).filter(key => isNaN(parseInt(key)) && key === key.toUpperCase()); }
}

